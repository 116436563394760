<script lang="ts" setup>
const route = useRoute()

const routes = computed(() => [
  {
    title: 'All',
    route: { name: 'explore', query: { query: route.query.query } },
  },
  {
    title: 'Radio',
    route: { name: 'radio', query: { query: route.query.query } },
  },
  {
    title: 'Podcasts',
    route: { name: 'podcasts', query: { query: route.query.query } },
  },
  {
    title: 'Bible',
    route: { name: 'bible', query: { query: route.query.query } },
  },
])
</script>

<template>
  <div class="space-x-2">
    <NuxtLink
      v-for="(item, index) in routes"
      :key="index"
      :to="item.route"
      class="inline-flex items-center px-4 py-1 border rounded-full whitespace-nowrap border-zeno-gray-border-light"
    >
      <span class="sm:text-base text-sm">
        {{ item.title }}
      </span>
    </NuxtLink>
  </div>
</template>

<style lang="postcss" scoped>
.router-link-active {
  @apply border-black;
}

.router-link-active.router-link-exact-active {
  @apply border-black;
}
</style>
